module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/render/project/src/src/components/layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/logo/logo_500.png","name":"Einbürgerungstest Flash Cards","short_name":"Einbürgerungstest","start_url":"/home","background_color":"#FFCC00","theme_color":"#FFCC00","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"42d5ce77d21630c950cee3b272cb0267"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
